<template>
  <div class="container-fluid pt-4">
    <div class="row">
      <div class="col-12 col-md-6">
        <div
          class="d-flex align-items-start align-items-md-end flex-column flex-md-row"
        >
          <router-link
            :to="{ name: 'Building List' }"
            v-slot="{ href, route, navigate }"
            custom
          >
            <a :href="href" @click="navigate" class="mr-2"
              ><i class="fa fa-chevron-left mr-1"></i>Back</a
            >
          </router-link>
          <h1 class="mb-0 mr-2">{{ pageTitle }}</h1>
          <!-- <a href="#" class="mr-2" v-b-modal.form-update-building v-b-tooltip.hover title="Update Building Name">
                        <i class="fa fa-edit"></i>
                    </a> -->
        </div>
      </div>

      <div class="col-12 col-md-6 mt-2 mt-md-0 text-left text-md-right">
        <b-button
          variant="secondary"
          size="sm"
          class="mr-2"
          v-b-modal.form-update-building
          v-b-tooltip.hover
          title="Update Building Name"
        >
          <i class="fa fa-edit"></i>
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="confirmDeleteBuilding"
          role="button"
          v-b-tooltip.hover
          title="Delete Building"
        >
          <i class="fa fa-trash-alt"></i>
        </b-button>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12 col-md-6 d-flex align-items-center">
        <h1 class="mb-1 mr-2">Floor List</h1>
        <span>Total Floors: {{ floorCount }}</span>
      </div>
      <div
        class="col-12 col-md-6 mt-2 mt-md-0 d-flex justify-content-start justify-content-md-end"
      >
        <div class="w-50 d-inline-block mr-2">
          <b-form-input
            id="search_bar"
            size="sm"
            type="text"
            placeholder="Search..."
            v-model="searchTerms"
            @input="searchFloorList"
          ></b-form-input>
        </div>

        <b-dropdown
          v-show="showMoreActions"
          class="mr-2"
          variant="secondary"
          size="sm"
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v mr-2"></i>{{ selectedText }}
          </template>
          <b-dropdown-item-button @click="confirmDeleteFloors"
            >Delete</b-dropdown-item-button
          >
        </b-dropdown>

        <b-button variant="success" size="sm" v-b-modal.form-add-floor
          ><i class="fa fa-plus mr-1"></i>Add Floor</b-button
        >
      </div>
    </div>

    <div class="floor-list pt-2">
      <BuildingFloorsTable
        ref="floorListTable"
        :buildingID="buildingID"
        @select-floor-event="floorSelected"
        v-if="buildingID != null"
      >
      </BuildingFloorsTable>
    </div>

    <b-modal
      id="form-update-building"
      title="Update Building Name"
      ref="formUpdateBuilding"
      centered
      hide-footer
    >
      <b-form @submit="onSubmitUpdateBuildingForm">
        <b-form-group
          id="input-group-name"
          label="Name:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="formUpdateBuilding.name"
            type="text"
            placeholder="Enter building name"
            required
          ></b-form-input>
        </b-form-group>

        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>

    <b-modal
      id="form-add-floor"
      title="Add Floor"
      ref="formAddFloor"
      centered
      hide-footer
    >
      <b-form @submit="onSubmitAddForm">
        <b-form-group
          id="input-group-name"
          label="Name:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="formAdd.name"
            type="text"
            placeholder="Enter floor name"
            required
          ></b-form-input>
        </b-form-group>

        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import BuildingFloorsTable from "@/components/SettingsFloorplan/BuildingFloorsTable/BuildingFloorsTable.vue";

let utils = require("@/assets/global/js/utils.js");

export default {
  components: {
    BuildingFloorsTable
  },
  data() {
    return {
      pageTitle: "",
      buildingID: null,
      buildingName: null,
      formAdd: {
        name: null
      },
      formUpdateBuilding: {
        name: null
      },
      showMoreActions: false,
      selectedText: "",
      selectedFloors: [],
      floorCount: 0,
      searchTerms: null
    };
  },
  computed: {
    ...mapGetters({
      isBuildingsInitiated: "psim/isBuildingsInitiated",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  watch: {
    isBuildingsInitiated: {
      handler: function (n, o) {
        // console.log(n);
        if (n == true) {
          this.initView();
        }
      },
      deep: true
    }
  },
  mounted: function () {
    if (this.isBuildingsInitiated) {
      this.initView();
    }
  },
  methods: {
    initView: function () {
      var $this = this;

      this.buildingID = parseInt(this.$route.params.building_id);
      // console.log(
      //   "Viewing SettingsFloorplan - BuildingDetail - Building ID:",
      //   this.buildingID
      // );

      var building_obj = this.$store.getters["psim/getBuilding"](
        this.buildingID
      );
      this.buildingName = building_obj.name;
      this.formUpdateBuilding.name = building_obj.name;
      $this.pageTitle = "Manage Building: " + building_obj.name;
      // $this.$parent.pageTitle = "Manage Building - " + building_obj.name;

      var floor_data_list = [];
      var floor_qs = this.$store.getters["psim/getFloors"]({
        building_id: this.buildingID
      });
      this.floorCount = floor_qs.length;
      for (var i in floor_qs) {
        if (floor_qs[i].default_img == null || floor_qs[i].default_img == "") {
          $this.fetchFloorPlan(floor_qs[i].id);
        }
      }
    },
    fetchFloorPlan: function (floor_id) {
      var $this = this;
      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        $this.buildingID +
        "/floors/" +
        floor_id +
        "/floorplans/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          var floorplan_image = null;
          var floorplan_list = [];
          if (data.result != undefined) {
            floorplan_list = data.result;
          }
          if (floorplan_list.length > 0) {
            floorplan_image = utils.cleanBase64ImgSrc(
              floorplan_list[0].image_b64,
              floorplan_list[0].mime_type
            );
          }
          $this.$store.dispatch("psim/updateFloor", {
            building_id: $this.buildingID,
            floor_id: floor_id,
            data: { default_img: floorplan_image }
          });
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    searchFloorList: function () {
      this.$refs.floorListTable.searchFloorList(this.searchTerms);
    },
    onSubmitAddForm: function (e) {
      e.preventDefault();
      var $this = this;
      // console.log(this.form);
      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        $this.buildingID +
        "/floors/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, $this.formAdd)
        .then((data) => {
          $this.$store.dispatch("psim/addFloor", {
            building_id: $this.buildingID,
            data: data
          });
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: data.name + " has been added successfully",
            message_type: "success"
          });
          $this.formAdd.name = null;
          $this.$refs.formAddFloor.hide();
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    onSubmitUpdateBuildingForm: function (e) {
      e.preventDefault();
      var $this = this;
      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        $this.buildingID +
        "/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, $this.formUpdateBuilding)
        .then((data) => {
          var update_data = {
            id: $this.buildingID,
            data: {
              name: $this.formUpdateBuilding.name
            }
          };
          $this.$store.dispatch("psim/updateBuilding", update_data);
          $this.$refs.formUpdateBuilding.hide();
          $this.buildingName = $this.formUpdateBuilding.name;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text:
              "Updated " + $this.formUpdateBuilding.name + " successfully",
            message_type: "success"
          });
          $this.formUpdateBuilding.name = null;
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    confirmDeleteBuilding: function (e) {
      e.preventDefault();
      var $this = this;
      $this.$bvModal
        .msgBoxConfirm(
          "All floors for " +
            $this.buildingName +
            " will be deleted as well. Confirm deleting " +
            $this.buildingName +
            "?",
          {
            centered: true
          }
        )
        .then((value) => {
          if (value == true) {
            var API_URL =
              $this.getAPIServerURL +
              "/floorplans/buildings/" +
              $this.buildingID +
              "/";
            var TOKEN_URL = $this.getAPIServerURL + "/auth/token/";
            const client = $this.$root.getAjaxFetchClient();
            client
              .deleteRequest(API_URL)
              .then((data) => {
                $this.$store.dispatch("psim/deleteBuilding", $this.buildingID);
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: "Building deleted successfully",
                  message_type: "success"
                });
                $this.$router.push({ name: "Building List" }).catch(() => {});

                // TOCLEAN
                // } else {
                //     $this.$store.dispatch('session/addGlobalAlertMessage', {"message_text": data.detail, "message_type": "danger"});
                // }
              })
              .catch((err) => {
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: err.detail,
                  message_type: "danger"
                });
                // if (err.status == 401) {
                //   $this.$store.dispatch("session/logoutSession");
                // }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    floorSelected: function (payload) {
      // console.log("floorSelected: ", payload);
      if (payload.length > 0) {
        this.showMoreActions = true;
        this.selectedText = payload.length + " selected";
        this.selectedFloors = payload;
      } else {
        this.showMoreActions = false;
        this.selectedText = "";
        this.selectedFloors = [];
      }
    },
    confirmDeleteFloors: function (e) {
      e.preventDefault();
      var $this = this;
      if ($this.selectedFloors.length > 0) {
        $this.$bvModal
          .msgBoxConfirm(
            "Confirm deleting " + $this.selectedFloors.length + " floors?",
            {
              centered: true
            }
          )
          .then((value) => {
            // console.log(value);
            if (value == true) {
              // Rearrange floors
              var floors = this.$store.getters["psim/getFloors"]({
                building_id: this.buildingID
              });
              // console.log(floors);
              var remaining_floors = [];
              for (var i in floors) {
                if ($this.selectedFloors.indexOf(floors[i].id) < 0) {
                  remaining_floors.push(floors[i]);
                }
              }
              // console.log(remaining_floors);
              var floor_arrangement = [];
              for (var r in remaining_floors) {
                floor_arrangement.push({
                  id: remaining_floors[r].id,
                  index: parseInt(r)
                });
                $this.$store.dispatch("psim/updateFloor", {
                  building_id: $this.buildingID,
                  floor_id: remaining_floors[r].id,
                  data: { index: parseInt(r) }
                });
              }
              // console.log(floor_arrangement);
              var API_URL =
                $this.getAPIServerURL +
                "/floorplans/buildings/" +
                $this.buildingID +
                "/floors/floors-index/";
              const client = $this.$root.getAjaxFetchClient();
              client
                .postRequest(API_URL, { floor_ids: floor_arrangement })
                .then((data) => {})
                .catch((err) => {
                  $this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text: err.detail,
                    message_type: "danger"
                  });
                  // if (err.status == 401) {
                  //   $this.$store.dispatch("session/logoutSession");
                  // }
                });

              // Delete floors
              for (var f in $this.selectedFloors) {
                var floor_id = $this.selectedFloors[f];
                $this.deleteFloor(floor_id);
              }
            }
          })
          .catch((err) => {
            // An error occurred
          });
      } else {
        $this.$bvModal.msgBoxOk("No floors selected", { centered: true });
      }
    },
    deleteFloor: function (floor_id) {
      var $this = this;
      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        $this.buildingID +
        "/floors/" +
        floor_id +
        "/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .deleteRequest(API_URL)
        .then((data) => {
          this.$store.dispatch("psim/deleteFloor", {
            building_id: $this.buildingID,
            floor_id: floor_id
          });
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Floor deleted successfully",
            message_type: "success"
          });

          // TOCLEAN
          // } else {
          //     this.$store.dispatch('session/addGlobalAlertMessage', {"message_text": data.detail, "message_type": "danger"});
          // }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    }
  }
};
</script>

<style lang="scss">
@import "./SettingsFloorplan.scss";
</style>
