<template>
  <b-table-simple sticky-header="100%" class="table-building-floors">
    <b-thead>
      <b-tr>
        <b-th style="width: 20px"></b-th>
        <b-th style="width: 40px">
          <b-form-checkbox
            id="floors-select-all"
            v-model="allFloorsSelected"
            name="select_all_floors"
            :value="true"
            :unchecked-value="false"
            @change="selectAllFloors"
          ></b-form-checkbox>
        </b-th>
        <b-th style="width: 40px">No.</b-th>
        <b-th>Floor</b-th>
        <b-th class="d-none d-md-table-cell">Devices</b-th>
        <b-th class="w-15">Actions</b-th>
      </b-tr>
    </b-thead>
    <draggable
      tag="b-tbody"
      ghost-class="ghost"
      v-model="floorArrangeableList"
      handle=".handle"
    >
      <b-tr
        v-for="data in floorArrangeableList"
        :key="data.id"
        :class="[data.isShow ? '' : 'd-none']"
      >
        <b-td>
          <div class="handle">
            <i class="fa fa-bars"></i>
          </div>
        </b-td>

        <b-td>
          <b-form-checkbox
            :id="'floors-select-' + data.id"
            v-model="floorsSelected"
            name="select_floors"
            :value="data.id"
            @change="selectSingleFloor"
          ></b-form-checkbox>
        </b-td>

        <b-td>
          {{ data.index + 1 }}
        </b-td>

        <b-td>
          <div class="d-flex align-items-center">
            <div
              class="floor-image-thumbnail mr-2"
              :class="[
                data.default_img == null
                  ? 'floor-image-empty'
                  : 'floor-image-exist'
              ]"
            >
              <img
                :src="data.default_img"
                v-if="data.default_img != null"
                @click="setFloorPlanIndex(data.id)"
              />
              <div class="no-img" v-else>No Image</div>
            </div>
            <router-link
              :to="{
                name: 'Floor Plan',
                params: { building_id: buildingID, floor_id: data.id }
              }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <a
                :href="href"
                @click="navigate"
                v-b-tooltip.hover
                title="View Floor Details"
                ><b>{{ data.name }}</b></a
              >
            </router-link>
          </div>
        </b-td>

        <b-td class="d-none d-md-table-cell">
          <div
            class="d-inline-flex align-items-center floor-device-icon-counter floor-device-icon-counter-camera mr-2"
            v-b-tooltip.hover
            :title="data.cameras.length + ' Cameras'"
          >
            <i class="fa fa-video"></i>
            <div class="floor-device-counter ml-2">
              {{ data.cameras.length }}
            </div>
          </div>
          <div
            class="d-inline-flex align-items-center floor-device-icon-counter floor-device-icon-counter-door"
            v-b-tooltip.hover
            :title="data.doors.length + ' Doors'"
          >
            <i class="fa fa-door-closed"></i>
            <div class="floor-device-counter ml-2">{{ data.doors.length }}</div>
          </div>
        </b-td>

        <b-td>
          <b-dropdown no-caret dropleft class="d-block d-md-none">
            <template #button-content>
              <i class="fa fa-ellipsis-v"></i>
            </template>
            <router-link
              :to="{
                name: 'Floor Plan',
                params: { building_id: buildingID, floor_id: data.id }
              }"
              v-slot="{ href, route, navigate }"
              custom
            >
              <b-dropdown-item :href="href" @click="navigate">
                <i class="fa fa-eye mr-1"></i> View Floor Details
              </b-dropdown-item>
            </router-link>

            <b-dropdown-item
              v-b-modal.form-update-floor
              @click="showUpdateFloorForm(data)"
            >
              <i class="fa fa-edit mr-1"></i> Update Floor
            </b-dropdown-item>

            <b-dropdown-item
              v-b-modal.form-update-floor
              @click="confirmDelete(data)"
              class="text-danger"
            >
              <i class="fa fa-trash-alt mr-1"></i> Delete Floor
            </b-dropdown-item>
          </b-dropdown>

          <router-link
            :to="{
              name: 'Floor Plan',
              params: { building_id: buildingID, floor_id: data.id }
            }"
            v-slot="{ href, route, navigate }"
            custom
          >
            <b-button
              size="sm"
              variant="secondary"
              :href="href"
              @click="navigate"
              v-b-tooltip.hover
              title="View Floor Details"
              class="mr-1 d-none d-md-inline-block"
              ><i class="fa fa-eye"></i
            ></b-button>
          </router-link>

          <b-button
            variant="secondary"
            size="sm"
            v-b-modal.form-update-floor
            v-b-tooltip.hover
            title="Update Floor"
            @click="showUpdateFloorForm(data)"
            class="mr-1 d-none d-md-inline-block"
          >
            <i class="fa fa-edit"></i>
          </b-button>

          <b-button
            variant="danger"
            size="sm"
            @click="confirmDelete(data)"
            v-b-tooltip.hover
            title="Delete Floor"
            class="d-none d-md-inline-block"
          >
            <i class="fa fa-trash-alt"></i>
          </b-button>
        </b-td>
      </b-tr>

      <b-tr v-if="floorListEmpty" class="table-empty-row">
        <b-td colspan="6">No floors found</b-td>
      </b-tr>
    </draggable>

    <b-modal
      title="'Update Floor"
      id="form-update-floor"
      ref="formUpdateFloor"
      centered
      hide-footer
    >
      <b-form @submit="onSubmitUpdateForm">
        <b-form-group
          id="input-group-name"
          label="Name:"
          label-for="input-name"
        >
          <b-form-input
            id="input-name"
            v-model="formUpdate.name"
            type="text"
            placeholder="Enter floor name"
            required
          ></b-form-input>
        </b-form-group>

        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </b-form>
    </b-modal>

    <CoolLightBox
      :items="floorPlanImages"
      :index="floorPlanIndex"
      @close="floorPlanIndex = null"
    >
    </CoolLightBox>
  </b-table-simple>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import CoolLightBox from "vue-cool-lightbox";
import AjaxFetch from "@/assets/global/js/AjaxFetch.js";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
let utils = require("@/assets/global/js/utils.js");

export default {
  name: "BuildingFloorsTable",
  components: {
    draggable,
    CoolLightBox
  },
  props: {
    buildingID: {
      type: Number,
      required: false
    }
  },
  computed: {
    floorArrangeableList: {
      get() {
        var $this = this;
        var floors = this.$store.getters["psim/getFloors"]({
          building_id: this.buildingID
        });
        // console.log("get floor list", this.buildingID, floors);
        for (var i in floors) {
          var camera_list = this.$store.getters["psim/getCameras"]({
            building__id: this.buildingID,
            floor__id: floors[i].id
          });
          var door_list = this.$store.getters["psim/getDoors"]({
            building__id: this.buildingID,
            floor__id: floors[i].id
          });
          floors[i]["cameras"] = camera_list;
          floors[i]["doors"] = door_list;

          if (
            floors[i].name
              .toUpperCase()
              .includes(this.searchTerms.toUpperCase())
          ) {
            floors[i]["isShow"] = true;
          } else {
            floors[i]["isShow"] = false;
          }
        }
        // console.log("get", floors);
        return floors;
      },
      set(value) {
        // console.log("set floor list");
        var floor_arrangement = [];
        // console.log(value)
        for (var i in value) {
          floor_arrangement.push({ id: value[i].id, index: parseInt(i) });
          value[i].index = parseInt(i);
        }
        // console.log(floor_arrangement)
        this.rearrangeFloors(floor_arrangement);
        var floor_data = { building_id: this.buildingID, floors: value };
        // console.log(floor_data)
        this.$store.dispatch("psim/setFloors", floor_data);
      }
    },
    ...mapGetters({
      isBuildingsInitiated: "psim/isBuildingsInitiated",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  watch: {
    floorArrangeableList: {
      handler: function (n, o) {
        var floorimage_index = 0;
        var is_empty = true;
        this.floorPlanImages = [];
        this.floorPlanImagesIndexed = {};

        for (var i in n) {
          var floor = n[i];
          if (floor.default_img != null) {
            this.floorPlanImagesIndexed[floor.id] = {
              img: floor.default_img,
              index: floorimage_index
            };
            var floorplan_data = {
              title: floor.name,
              description: "Floor number: " + (floor.index + 1),
              src: floor.default_img
            };
            this.floorPlanImages.push(floorplan_data);
            floorimage_index += 1;
          }
          if (floor.isShow) {
            is_empty = false;
          }
        }

        this.floorListEmpty = is_empty;
      },
      deep: true
    },
    isBuildingsInitiated: {
      handler: function (n, o) {
        if (n == true) {
          // TOCLEAN
          // var company_id = this.companyID;
          var building_id = this.buildingID;
          // this.$store.dispatch("psim/fetchBuildingFloors", {"building_id": building_id}).then(data => {});
        }
      },
      deep: true
    }
  },
  data: function () {
    return {
      floorsTableFields: null,
      floorsSelected: [],
      allFloorsSelected: false,
      formUpdate: {
        name: null
      },
      formUpdateID: null,
      floorPlanIndex: null,
      floorPlanImages: [],
      floorPlanImagesIndexed: {},
      floorListEmpty: true,
      searchTerms: ""
      // floorListOriginal: [],
    };
  },
  mounted: function () {
    this.floorsTableFields = [
      {
        key: "index",
        label: "Floor Number",
        thStyle: "width: 120px",
        tdClass: "text-left",
        sortable: true
      },
      "floor",
      "actions"
    ];

    if (this.isBuildingsInitiated) {
      // TOCLEAN
      // var company_id = this.companyID;
      var building_id = this.buildingID;
      // this.$store.dispatch("psim/fetchBuildingFloors", {"building_id": building_id}).then(data => {});
    }

    if (this.floorArrangeableList.length > 0) {
      this.floorListEmpty = false;
    } else {
      this.floorListEmpty = true;
    }
  },
  methods: {
    searchFloorList: function (search_terms) {
      var $this = this;
      this.searchTerms = search_terms;
    },
    rearrangeFloors: function (arrangement) {
      var $this = this;
      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        $this.buildingID +
        "/floors/floors-index/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, { floor_ids: arrangement })
        .then((data) => {
          if (data == null) {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Updated floors sequence successfully",
              message_type: "success"
            });
          } else if (data != null && data.detail != undefined) {
            $this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: data.detail,
              message_type: "danger"
            });
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    selectAllFloors: function (e) {
      var floors_id_list = [];
      if (e == true) {
        for (var i in this.floorArrangeableList) {
          floors_id_list.push(this.floorArrangeableList[i].id);
        }
      }
      this.floorsSelected = floors_id_list;
      this.$emit("select-floor-event", this.floorsSelected);
    },
    selectSingleFloor: function (e) {
      this.$emit("select-floor-event", this.floorsSelected);
    },
    showUpdateFloorForm: function (floor_data) {
      this.formUpdate.name = floor_data.name;
      this.formUpdateID = floor_data.id;
    },
    onSubmitUpdateForm: function (e) {
      e.preventDefault();
      var $this = this;
      var API_URL =
        $this.getAPIServerURL +
        "/floorplans/buildings/" +
        $this.buildingID +
        "/floors/" +
        $this.formUpdateID +
        "/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, $this.formUpdate)
        .then((data) => {
          // console.log(data);

          $this.$store.dispatch("psim/updateFloor", {
            building_id: $this.buildingID,
            floor_id: $this.formUpdateID,
            data: { name: $this.formUpdate.name }
          });
          $this.$refs.formUpdateFloor.hide();
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: "Updated " + $this.formUpdate.name + " successfully",
            message_type: "success"
          });
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    confirmDelete: function (floor_data) {
      // e.preventDefault();
      var $this = this;
      this.$bvModal
        .msgBoxConfirm("Confirm deleting " + floor_data.name + "?", {
          centered: true
        })
        .then((value) => {
          if (value == true) {
            var API_URL =
              $this.getAPIServerURL +
              "/floorplans/buildings/" +
              $this.buildingID +
              "/floors/" +
              floor_data.id +
              "/";
            const client = $this.$root.getAjaxFetchClient();
            client
              .deleteRequest(API_URL)
              .then((data) => {
                this.$store.dispatch("psim/deleteFloor", {
                  building_id: $this.buildingID,
                  floor_id: floor_data.id
                });
                this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: "Deleted floor successfully",
                  message_type: "success"
                });

                // TOCLEAN
                // } else {
                //     this.$store.dispatch('session/addGlobalAlertMessage', {"message_text": data.detail, "message_type": "danger"});
                // }
              })
              .catch((err) => {
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: err.detail,
                  message_type: "danger"
                });
                // if (err.status == 401) {
                //   $this.$store.dispatch("session/logoutSession");
                // }
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    setFloorPlanIndex: function (floor_id) {
      var floor_image = this.floorPlanImagesIndexed[floor_id];
      if (floor_image != undefined) {
        this.floorPlanIndex = floor_image.index;
      }
    }
  }
};
</script>

<style lang="scss">
@import "./BuildingFloorsTable.scss";
</style>
